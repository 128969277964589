import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6aa5af17"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  id: "vsscript_144563_140127",
  class: "beatbattle-wrapper"
};
import { onMounted, onBeforeUnmount, reactive } from "vue";
export default {
  setup: function setup(__props) {
    var state = reactive({
      loaded: false,
      scriptElement: null,
      metaTags: []
    });
    /**
     * Loads the Viralsweep script, then calls identify() if a user is logged in.
     */

    var loadScript = function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = 'https://app.viralsweep.com/vsa-full-6d38f1-144563.js?sid=144563_140127';
      document.head.appendChild(script);
      script.addEventListener('load', function () {
        state.loaded = true;
        appendMetaTagsToDocumentHead();
        killInnerScrollbar();
      });
      state.scriptElement = script;
    };
    /**
     * Remove the script element from the head when the component is unmounted
     */


    var unloadScript = function unloadScript() {
      if (state.scriptElement && state.scriptElement.parentNode) {
        state.scriptElement.parentNode.removeChild(state.scriptElement);
      }
    };
    /**
     * Append viralsweep meta tags to document head. First, check if a meta tag with the same property or name already exists, and remove it if so.
     */


    var appendMetaTagsToDocumentHead = function appendMetaTagsToDocumentHead() {
      var tagContents = [{
        property: "fb:app_id",
        content: "424426844274364"
      }, {
        name: "twitter:card",
        content: "summary_large_image"
      }, {
        name: "twitter:title",
        content: "VOCAL PRODUCER Beat Battle"
      }, {
        name: "twitter:description",
        content: "Submit your video and let your fans know why your track deserves their vote. MAKE A TRACK • SUBMIT A VIDEO • GET VOTES Meet our judge JESS JACKSON"
      }, {
        name: "twitter:image",
        content: ""
      }, {
        property: "og:url",
        content: "https://app.viralsweep.com/contest/full/6d38f1-144563?framed=1%22/"
      }, {
        property: "og:type",
        content: "article"
      }, {
        property: "og:title",
        content: "VOCAL PRODUCER Beat Battle"
      }, {
        property: "og:site_name",
        content: "VOCAL PRODUCER Beat Battle"
      }, {
        property: "fb:admins",
        content: "1088490351"
      }, {
        property: "fb:admins",
        content: "11512735"
      }, {
        property: "fb:admins",
        content: "1088490312"
      }, {
        property: "og:description",
        content: "Submit your video and let your fans know why your track deserves their vote. MAKE A TRACK • SUBMIT A VIDEO • GET VOTES Meet our judge JESS JACKSON"
      }];
      tagContents.forEach(function (tag) {
        var existingMetaTag = document.head.querySelector("meta[property=\"".concat(tag.property, "\"], meta[name=\"").concat(tag.name, "\"]"));

        if (existingMetaTag) {
          existingMetaTag.parentNode.removeChild(existingMetaTag);
        }

        var metaTag = document.createElement('meta');

        if (tag.name) {
          metaTag.name = tag.name;
        }

        if (tag.property) {
          metaTag.setAttribute('property', tag.property);
        }

        if (typeof tag.content !== 'undefined') {
          metaTag.content = tag.content;
        }

        document.head.appendChild(metaTag);
        state.metaTags.push(metaTag);
      });
    };
    /**
     * Remove viralsweep meta tags from document head
     */


    var removeMetaTagsFromDocumentHead = function removeMetaTagsFromDocumentHead() {
      state.metaTags.forEach(function (tag) {
        if (tag.parentNode) {
          tag.parentNode.removeChild(tag);
        }
      });
    };
    /**
     * Adjust height of iframe in order to prevent a double scroll bar problem
     */


    var killInnerScrollbar = function killInnerScrollbar() {
      var iframe = document.getElementById('vs_full_frame');
      iframe.style.position = 'relative';
      iframe.style.height = "400vh";
    };

    onMounted(function () {
      loadScript();
    });
    onBeforeUnmount(function () {
      unloadScript();
      removeMetaTagsFromDocumentHead();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};